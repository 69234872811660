.footer-wrapper {
  max-width: 1400px;
  height: 85px;
  padding: 0 60px;
  margin: 0 auto;

  transition: 0.3s;
}

.footer {
  display: flex;
  justify-content: space-between;

  height: 100%;
  padding: 0 10px 0 10px;
  margin: 0;

  list-style: none;

  li.footer-general,
  li.footer-pitch,
  li.footer-nav {
    width: 33.33333%;
  }

  &-general {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .links {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0;
      margin: 0;

      list-style: none;

      li {
        margin: 0 15px;
      }
    }
  }

  &-pitch {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
      width: 100px;
      height: 25px;

      a {
        height: 100%;
        width: 100%;

        svg {
          height: 100%;
          width: 100%;
        }
      }
    }

    p {
      margin: 8px 0 0 0;

      font-size: 12px;
      font-weight: normal;
    }
  }

  &-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ul {
      // display: flex;// flex-direction: column;
      // justify-content: center;
      // align-items: flex-end;

      padding: 0;
      margin: 0;

      list-style: none;

      li {
        // min-width: 120px;
        margin-bottom: 5px;

        text-align: center;

        a {
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: 0.85px;
          text-decoration: none;

          transition: 0.3s;

          &:hover {
            color: #a8a1a1;
          }
        }
      }

      li a.active {
        color: #46bffc;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .footer-wrapper {
    height: 130px;
    padding: 0 20px;
  }

  .footer {
    display: flex;
    justify-content: space-between;

    height: 100%;
    padding: 25px 0;
    margin: 0;

    list-style: none;

    li.footer-general,
    li.footer-pitch {
      width: 50%;
    }

    &-general {
      .logo {
        width: 100px;
        height: 25px;
        margin-bottom: 20px;
      }

      svg {
        height: 100%;
        width: 100%;
      }

      p {
        margin: 0;

        color: #8e8e8e;

        font-size: 11px;
        font-weight: normal;

        &.name {
          margin-bottom: 3px;
        }
      }
    }

    &-pitch {
      display: flex;
      flex-direction: column;

      padding: 0;

      p.email,
      p.phone {
        margin: 0;

        font-size: 15px;
        font-weight: 800;
        text-align: center;

        a {
          text-decoration: none;
        }

        &:hover {
          a {
            text-decoration: underline;
          }
        }
      }

      p.email {
        order: 2;
      }

      p.phone {
        order: 3;
      }

      .links {
        display: flex;
        justify-content: center;

        padding: 0;
        margin-top: 0;
        margin-bottom: 15px;

        list-style: none;

        order: 1;

        li {
          margin: 0 15px;

          svg {
            width: 15px;
            height: 15px;
          }
        }
      }
    }

    &-nav {
      display: none;
    }
  }
}

@media screen and (min-width: 476px) and (max-width: 768px) {
  .footer-wrapper {
    height: unset;
    padding: 0 20px;
  }

  .footer {
    display: flex;
    justify-content: space-between;

    height: 100%;
    padding: 15px 0;
    margin: 0;

    list-style: none;

    li.footer-general,
    li.footer-pitch {
      width: 50%;
    }

    &-general {
      .logo {
        width: 150px;
        margin-bottom: 10px;
      }

      svg {
        height: 100%;
        width: 100%;
      }

      p {
        margin: 0;

        color: #8e8e8e;

        font-size: 14px;
        font-weight: normal;

        &.name {
          margin-bottom: 3px;
        }
      }
    }

    &-pitch {
      display: flex;
      flex-direction: column;

      padding: 0;

      p.email,
      p.phone {
        margin: 0;

        font-size: 18px;
        font-weight: 800;
        text-align: right;

        a {
          text-decoration: none;
        }

        &:hover {
          a {
            text-decoration: underline;
          }
        }
      }

      p.email {
        order: 2;
      }

      p.phone {
        order: 3;
      }

      .links {
        display: flex;
        justify-content: flex-end;

        padding: 0;
        margin-top: 0;
        margin-bottom: 15px;

        list-style: none;

        order: 1;

        li {
          margin: 0 15px;

          svg {
            width: 25px;
            height: 25px;
          }
        }
      }
    }

    &-nav {
      display: none;
    }
  }
}
