.shooting-in-wrapper {
  position: relative;

  min-height: calc(100vh - 175px);
  padding-bottom: 85px;

  &.black-arrows:not(.always-white) {
    .control-prev {
      background: url('../../../style/source/icons/arrow-prev-black.svg') no-repeat center center;

      transform: rotate(0deg);
      transition: 0s;

      &:hover {
        background: url('../../../style/source/icons/arrow-prev-black.svg') no-repeat center center;
      }
    }

    .control-next {
      background: url('../../../style/source/icons/arrow-next-black.svg') no-repeat center center;

      &:hover {
        background: url('../../../style/source/icons/arrow-next-black.svg') no-repeat center center;
      }
    }

    .control-dots {
      li {
        box-shadow: none;

        background-color: black;
      }
    }
  }
}

.unsetMaxHeight {
  max-height: unset;
}

.shooting-in {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;

  .image {
    height: 100%;
    width: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;

    &-overlay {
      position: absolute;
      top: 0;

      height: 100vh;
      width: 100%;

      background-color: rgba($color: black, $alpha: 0.2);
    }
  }

  .image.ukraine {
    background-image: url('../../../style/source/icons/ukraine.jpg');
  }

  .image.georgia {
    background-image: url('../../../style/source/images/georgia/georgia_photo_16.jpg');
  }

  .image.kazakhstan {
    background-image: url('../../../style/source/icons/kazakhstan.jpg');
  }

  .bg-darken {
    background-color: rgba($color: black, $alpha: 0.4);
  }

  .container {
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;
    max-width: 1300px;
    padding: 0 70px;
    margin: 0 auto;

    .shooting-in-country {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 100%;
      height: 100vh;

      .left {
        flex-grow: 0.35;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        h1,
        p {
          margin: 0;

          color: white;
        }

        p {
          font-size: 26px;
          letter-spacing: 2px;
          text-transform: uppercase;
        }

        h1 {
          font-size: 81px;
          font-weight: 900;
        }
      }

      p {
        font-size: 26px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      .countries {
        flex-grow: 0.35;
        display: flex;
        align-items: flex-end;

        a {
          margin-right: 20px;

          color: #46bffc;

          font-size: 37px;
          font-weight: 500;

          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .shooting-in-description {
      display: flex;
      flex-direction: column;
      justify-content: center;

      width: 100%;
      height: 100vh;
      padding: 0 0 0 15px;

      p {
        color: white;

        font-size: 15px;
        line-height: 1.35;
        letter-spacing: 0.8px;
      }
    }
  }
}

.show-more-block {
  display: flex;
  justify-content: center;
}

.shooting-in-description-mobile {
  display: none;
}

.country-carousel-wrapper {
  padding: 70px 0 0 0;

  h1 {
    margin-bottom: 45px;

    font-size: 37px;
    font-weight: 500;
    text-align: center;
  }

  .carousel-wrapper {
    .carousel.carousel-slider {
      display: flex;
      justify-content: flex-start;

      height: 765px;

      .control-prev {
        left: 25%;
        right: unset;
      }

      .control-next {
        right: 25%;
        left: unset;
      }

      .control-dots {
        left: 50%;
        transform: translateX(-50%);
      }

      .control-prev,
      .control-next,
      .control-dots {
        bottom: 30px;

        height: 40px;
      }
    }

    .slider-wrapper {
      margin: 0;
      height: 665px;

      .slider {
        height: 100%;

        .slide {
          padding: 35px 45px;

          transition: all 0.3s;
          opacity: 0.25;

          img {
            height: 100%;

            object-fit: cover;
          }
        }

        .slide.selected {
          padding: 0;

          opacity: 1;
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 475px) {
  .shooting-in-wrapper {

    &.always-white {
      & .team-members-carousel {
        z-index: 9999;
      }

      .control-prev {
        left: 10%;
        right: unset;
      }

      .control-next {
        right: 10% !important;
        left: unset;
      }

      .control-dots {
        left: 50%;
        transform: translateX(-50%);

        width: initial !important;
      }

      .control-prev,
      .control-next,
      .control-dots {
        bottom: 140px;

        height: 40px;
      }

      .stop {
        top: 90px !important;
      }

      .carousel.carousel-slider {
        max-height: unset !important;
        height: unset !important;

        .slider-wrapper {
          max-height: unset !important;
          height: unset !important;

          li {
            img {
              object-fit: contain !important;
            }
          }
        }
      }
    }

    .shooting-in {
      .container {
        padding: 0 30px;
      }

      .shooting-in-country {
        .left {
          p {
            font-size: 28px;
          }

          h1 {
            font-size: 45px !important;
          }
        }

        .countries {
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;

          a {
            margin: 0;

            font-size: 30px;
          }
        }
      }
    }

    .carousel.carousel-slider {
      max-height: 335px !important;
      height: auto;

      .slider-wrapper {
        max-height: 335px !important;
        height: auto;

        li {
          img {
            object-fit: contain !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 476px) and (max-width: 991px) {
  .shooting-in {
    .carousel.carousel-slider {
      max-height: 500px !important;

      .slider-wrapper {
        max-height: 500px !important;

        li {
          img {
            object-fit: contain !important;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 991px) {
  .shooting-in-wrapper {
    &.always-white {

      & .team-members-carousel {
        z-index: 9999;
      }

      .control-prev {
        left: 10%;
        right: unset;
      }

      .control-next {
        right: 10% !important;
        left: unset;
      }

      .control-dots {
        left: 50%;
        transform: translateX(-50%);

        width: initial !important;
      }

      .control-prev,
      .control-next,
      .control-dots {
        bottom: 140px;

        height: 40px;
      }

      .stop {
        top: 90px !important;
      }
    }

    .shooting-in-country {
      .left {
        p {
          font-size: 28px;
        }

        h1 {
          font-size: 60px !important;
        }
      }
    }

    .country-carousel-wrapper {
      padding: 35px 0;

      .carousel-wrapper {
        .carousel-root {
          .control-prev {
            left: 10%;
            right: unset;
          }

          .control-next {
            right: 10% !important;
            left: unset;
          }

          .control-dots {
            left: 50%;
            transform: translateX(-50%);

            width: initial !important;
          }

          .control-prev,
          .control-next,
          .control-dots {
            bottom: 20px;

            height: 40px;
          }
        }
      }
    }

    .shooting-in-description {
      display: none !important;
    }

    .shooting-in-description-mobile {
      display: block !important;

      padding: 25px 20px 0 20px !important;

      p {
        font-size: 15px;
        line-height: 1.35;
        letter-spacing: 0.8px;
      }
    }

    .country-carousel-wrapper .carousel-wrapper .slider-wrapper .slider .slide {
      padding: 25px 15px;
    }

    .country-carousel-wrapper .carousel-wrapper .slider-wrapper .slider .slide.selected {
      padding: 0;
    }
  }
}