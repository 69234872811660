.gallery-wrapper {
  position: relative;

  min-height: calc(100vh - 120px);
  // height: calc(100vh - 175px);
  padding-top: 170px;
  padding-bottom: 85px;

  // overflow-y: scroll;
}

.gallery-wrapper.opened {
  z-index: 99999;
}

.photos {
  height: 100%;
  max-width: 1040px;
  margin: 0 auto;

  ul {
    margin: 0;

    list-style: none;
  }

  &-filters {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 0 25px 0;

    li {
      display: flex;

      padding: 0 10px;

      color: #8e8e8e !important;

      font-weight: 500;
      font-size: 16px;
      text-align: center;

      transition: 0.3s;

      span {
        cursor: pointer;
      }

      &.active {
        text-decoration: underline;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 45px;
        margin: 0 0 0 10px;

        .edit:hover,
        .delete:hover {
          color: #46bffc;

          transition: 0.3s;
        }
      }
    }
  }

  &-items {
    padding: 0 20px;

    ul {
      padding-left: 0;

      columns: 3;
      column-gap: 1rem;

      li {
        position: relative;

        margin: 0 1rem 1rem 0;
        width: 100%;

        color: white;

        .thumbnail {
          height: 100%;
          width: 100%;

          object-fit: cover;
        }

        .description {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          background-color: rgba(0, 0, 0, 0);

          text-align: center;

          backdrop-filter: blur(5px);
          transition: 0.3s;
          opacity: 0;
          cursor: pointer;

          &-title {
            font-size: 19px;
            font-weight: 900;
          }

          &-author-name {
            font-size: 16px;
            letter-spacing: 1.25px;
          }

          &-title,
          &-author-name {
            color: white;
          }

          .actions {
            position: absolute;
            bottom: 5px;
            right: 5px;

            display: flex;

            .delete,
            .edit {
              margin-left: 5px;
              border-radius: 5px;

              color: white;

              font-size: 35px;

              transition: 0.3s;

              &:hover {
                color: #46bffc;
              }
            }
          }
        }

        p {
          margin: 0;
        }

        &:hover {
          .description {
            background-color: rgba(0, 0, 0, 0.45);

            opacity: 1;
          }
        }
      }

      @for $i from 1 through 36 {
        li:nth-child(#{$i}) {
          $h: (random(150) + 100) + px;
          height: $h;
        }
      }

      &.left {
        li {
          padding: 0 8px 16px 0;

          .description {
            padding: 0 35px 16px 35px;
            margin: 0px 8px 16px 0;
          }
        }
      }

      &.right {
        li {
          padding: 0 0 16px 8px;

          .description {
            padding: 0 35px 16px 35px;
            margin: 0px 0 16px 8px;
          }
        }
      }
    }
  }

  .new {
    display: flex;

    .new-photo,
    .new-category {
      position: relative;

      width: 100%;
      // margin: 0 0 16px 0;

      border-radius: 10px;

      transition: 0.3s;

      .add-new {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
        padding: 0;
        margin: 0;

        transition: 0.3s;
        cursor: pointer;

        .title {
          font-size: 25px;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1.5px;

          transition: 0.3s;
        }
      }

      &:hover {
        border-color: #46bffc;

        .add-new {
          .title {
            color: #46bffc;
          }
        }
      }
    }

    .new-photo {
      margin: 0 8px 16px 0;
    }

    .new-category {
      margin: 0 0 16px 8px;
    }
  }

  &.empty {
    display: flex;
    flex-direction: column;

    .empty-block {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
    }

    .no-photos {
      margin: 0;

      font-size: 50px;
      font-weight: 800;
      letter-spacing: 3px;
    }
  }
}

// rewritten antd modal's styles

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.75);
}

.ant-modal-footer {
  .ant-btn {
    &:hover {
      border: 1px solid #46bffc;

      background-color: #46bffc;

      color: white;
    }

    &.ant-btn-primary {
      border: 1px solid #46bffc;

      background-color: #46bffc;

      color: white;

      &:hover {
        border: 1px solid #46bffc;

        background-color: white;

        color: #46bffc;
      }
    }
  }
}

.carousel-member {
  .inner {
    img {
      width: 100% !important;
    }
  }
}

.action-button-edit-modal {
  margin-top: 15px;
  margin-right: 10px;
  padding: 10px 20px;
  border: 1px solid #46bffc;
  border-radius: 5px;

  background: transparent;
  color: #46bffc;

  font-size: 16px;

  transition: 0.3s;
  cursor: pointer;

  &:hover {
    border: 1px solid #46bffc;

    background-color: #46bffc;
    color: white;
  }
}

.video iframe,
.video .vimeo {
  min-height: 600px;
  width: 100%;
  margin: 0;
}

@media screen and (max-width: 475px) {
  .gallery-wrapper {
    position: relative;

    min-height: calc(100vh - 75px);
    padding-top: 80px;
    padding-bottom: 45px;
  }

  .photos {
    height: 100%;
    max-width: 1040px;
    margin: 0 auto;

    ul {
      margin: 0;

      list-style: none;
    }

    &-filters {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 0 0 25px 0;

      li {
        display: flex;

        padding: 0 10px;

        color: #8e8e8e !important;

        font-weight: 500;
        font-size: 20px;
        text-align: center;

        transition: 0.3s;

        span {
          cursor: pointer;
        }

        &.active {
          text-decoration: underline;
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 45px;
          margin: 0 0 0 10px;

          .edit:hover,
          .delete:hover {
            color: #46bffc;

            transition: 0.3s;
          }
        }
      }
    }

    &-items {
      padding: 0 20px;

      ul {
        padding-left: 0;

        columns: 1;
        column-gap: 1rem;

        li {
          position: relative;

          margin: 0 1rem 1rem 0;
          width: 100%;

          color: white;

          .thumbnail {
            height: 100%;
            width: 100%;

            object-fit: cover;
          }

          .description {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            background-color: rgba(0, 0, 0, 0);

            text-align: center;

            backdrop-filter: blur(3px);
            transition: 0.3s;
            opacity: 0;
            cursor: pointer;

            &-title {
              font-size: 19px;
              font-weight: 900;
            }

            &-author-name {
              font-size: 16px;
              letter-spacing: 1.25px;
            }

            &-title,
            &-author-name {
              color: white;
            }

            .actions {
              position: absolute;
              bottom: 5px;
              right: 5px;

              display: flex;

              .delete,
              .edit {
                margin-left: 5px;
                border-radius: 5px;

                color: white;

                font-size: 35px;

                transition: 0.3s;

                &:hover {
                  color: #46bffc;
                }
              }
            }
          }

          p {
            margin: 0;
          }

          &:hover {
            .description {
              background-color: rgba(0, 0, 0, 0.75);

              opacity: 1;
            }
          }
        }

        @for $i from 1 through 36 {
          li:nth-child(#{$i}) {
            $h: (random(50) + 200) + px;
            height: $h;
          }
        }

        &.left {
          li {
            padding: 0 8px 16px 0;

            .description {
              padding: 0 35px 16px 35px;
              margin: 0px 8px 16px 0;
            }
          }
        }

        &.right {
          li {
            padding: 0 0 16px 8px;

            .description {
              padding: 0 35px 16px 35px;
              margin: 0px 0 16px 8px;
            }
          }
        }
      }
    }

    .new {
      display: flex;
      flex-direction: column;

      padding: 0 20px;

      .new-photo,
      .new-category {
        position: relative;

        width: 100%;
        margin: 0 0 16px 0;

        border-radius: 10px;

        transition: 0.3s;

        .add-new {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          height: 100%;
          padding: 0;
          margin: 0;

          transition: 0.3s;
          cursor: pointer;

          .title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1.5px;

            transition: 0.3s;
          }
        }

        &:hover {
          border-color: #46bffc;

          .add-new {
            .title {
              color: #46bffc;
            }
          }
        }
      }
    }

    &.empty {
      display: flex;
      flex-direction: column;

      .empty-block {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
      }

      .no-photos {
        margin: 0;

        font-size: 35px;
        font-weight: 800;
        letter-spacing: 3px;
      }
    }
  }

  .carousel-member {
    .inner {
      img {
        width: 100% !important;
      }
    }
  }
}

@media screen and (min-width: 476px) and (max-width: 768px) {
  .gallery-wrapper {
    position: relative;

    min-height: calc(100vh - 75px);
    padding-top: 120px;
    padding-bottom: 45px;
  }

  .photos {
    height: 100%;
    max-width: 1040px;
    margin: 0 auto;

    ul {
      margin: 0;

      list-style: none;
    }

    &-filters {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 0 0 25px 0;

      li {
        display: flex;

        padding: 0 10px;

        color: #8e8e8e !important;

        font-weight: 500;
        font-size: 20px;
        text-align: center;

        transition: 0.3s;

        span {
          cursor: pointer;
        }

        &.active {
          text-decoration: underline;
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 45px;
          margin: 0 0 0 10px;

          .edit:hover,
          .delete:hover {
            color: #46bffc;

            transition: 0.3s;
          }
        }
      }
    }

    &-items {
      padding: 0 20px;

      ul {
        padding-left: 0;

        columns: 2;
        column-gap: 1rem;

        li {
          position: relative;

          margin: 0 1rem 1rem 0;
          width: 100%;

          color: white;

          .thumbnail {
            height: 100%;
            width: 100%;

            object-fit: cover;
          }

          .description {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            background-color: rgba(0, 0, 0, 0);

            text-align: center;

            backdrop-filter: blur(3px);
            transition: 0.3s;
            opacity: 0;
            cursor: pointer;

            &-title {
              font-size: 19px;
              font-weight: 900;
            }

            &-author-name {
              font-size: 16px;
              letter-spacing: 1.25px;
            }

            &-title,
            &-author-name {
              color: white;
            }

            .actions {
              position: absolute;
              bottom: 5px;
              right: 5px;

              display: flex;

              .delete,
              .edit {
                margin-left: 5px;
                border-radius: 5px;

                color: white;

                font-size: 35px;

                transition: 0.3s;

                &:hover {
                  color: #46bffc;
                }
              }
            }
          }

          p {
            margin: 0;
          }

          &:hover {
            .description {
              background-color: rgba(0, 0, 0, 0.75);

              opacity: 1;
            }
          }
        }

        @for $i from 1 through 36 {
          li:nth-child(#{$i}) {
            $h: (random(50) + 200) + px;
            height: $h;
          }
        }

        &.left {
          li {
            padding: 0 8px 16px 0;

            .description {
              padding: 0 35px 16px 35px;
              margin: 0px 8px 16px 0;
            }
          }
        }

        &.right {
          li {
            padding: 0 0 16px 8px;

            .description {
              padding: 0 35px 16px 35px;
              margin: 0px 0 16px 8px;
            }
          }
        }
      }
    }

    .new {
      display: flex;
      flex-direction: column;

      padding: 0 20px;

      .new-photo,
      .new-category {
        position: relative;

        width: 100%;
        margin: 0 0 16px 0;

        border-radius: 10px;

        transition: 0.3s;

        .add-new {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          height: 100%;
          padding: 0;
          margin: 0;

          transition: 0.3s;
          cursor: pointer;

          .title {
            font-size: 25px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1.5px;

            transition: 0.3s;
          }
        }

        &:hover {
          border-color: #46bffc;

          .add-new {
            .title {
              color: #46bffc;
            }
          }
        }
      }
    }

    &.empty {
      display: flex;
      flex-direction: column;

      .empty-block {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
      }

      .no-photos {
        margin: 0;

        font-size: 35px;
        font-weight: 800;
        letter-spacing: 3px;
      }
    }
  }

  .carousel-member {
    height: 100%;

    .inner {
      height: 100%;

      img {
        height: 100%;
        width: 100% !important;

        object-fit: contain;
      }
    }
  }
}
