.editSlide {
  .action-button-edit-modal {
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
    padding: 10px 20px;
    border: 1px solid #46bffc;
    border-radius: 5px;

    background: transparent;
    color: #46bffc;

    font-size: 16px;

    transition: 0.3s;
    cursor: pointer;

    &:hover {
      border: 1px solid #46bffc;

      background-color: #46bffc;
      color: white;
    }
  }
}
