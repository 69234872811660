.contacts-wrapper {
  position: relative;

  display: flex;

  min-height: 100vh;
  // height: calc(100vh - 175px);
  // max-width: 1160px;
  padding: 120px 0 40px 0;
  // margin: 0 auto;

  // overflow-y: scroll;
  // transition: 0.3s;
}

.contacts {
  width: 40%;
  padding: 45px 70px 0 120px;

  &-description {
    margin-right: 15px;

    p {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.75px;

      &.title {
        margin: 0;

        text-transform: uppercase;
        font-size: 19px;
        font-weight: 900;
      }

      &.description {
        margin: 0;

        font-size: 16px;
        line-height: 1.2;
        letter-spacing: 1.25px;
      }

      a {
        text-decoration: none;
      }
    }

    p:first-child {
      margin-top: 0;
    }
  }

  .section {
    margin: 40px 0;
  }
}

.map {
  width: 100%;
  padding: 0 50px 0 0;

  .map-inner {
    height: calc(100vh - 160px);
    order: 2;
  }
}

.country {
  display: block;

  margin: 0;

  color: #46bffc;

  font-size: 37px;
  font-weight: 500;
  line-height: 1.25;

  &:hover {
    text-decoration: underline;
  }
}

.marker-style {
  span {
    font-size: 19px;
    color: #46bffc;
    font-weight: bold;
    margin-left: 5px;
    line-height: 1.25;
    letter-spacing: 1px;
    font-family: 'Maven Pro', -apple-system, 'Segoe UI', Roboto, Helvetica,
      Arial, sans-serif !important;
  }
}

@media screen and (max-width: 1200px) {
  .contacts-wrapper {
    position: relative;

    display: flex;
    flex-direction: column;

    min-height: calc(100vh - 175px);
    padding: 100px 0 0 0;
  }

  .contacts {
    width: 100%;
    padding: 20px 20px 0 20px;

    order: 2;

    &-description {
      margin-right: 0;

      p {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.75px;

        &.title {
          margin: 0;

          text-transform: uppercase;
          font-size: 20px;
          font-weight: 900;
        }

        &.description {
          margin: 0;

          font-size: 20px;
          line-height: 1.2;
          letter-spacing: 1.25px;
        }

        a {
          text-decoration: none;
        }
      }

      p:first-child {
        margin-top: 0;
      }
    }

    .section {
      margin: 20px 0;
    }
  }

  .map {
    padding: 0;

    .map-inner {
      height: 450px;
      order: 1;
    }
  }
}

@media screen and (max-width: 475px) {
  .contacts-wrapper {
    padding: 70px 0 0 0;
  }
}