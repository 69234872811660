.carousel.carousel-slider {
  position: relative;

  .image-carousel {
    position: relative;

    height: 100vh;

    cursor: pointer;

    .image {
      height: 100%;
      width: 100%;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50%;
    }

    .slide-inner {
      position: absolute;
      top: 50%;

      padding: 0 100px 0 100px;
      // max-height: 600px;
      width: 100%;

      text-align: left;

      overflow: hidden;
      transform: translateY(-50%);

      h1 {
        margin: 25px 0;

        color: white;

        font-weight: 900;
        text-transform: uppercase;
        font-size: 57px;
        letter-spacing: 1px;
        line-height: normal;
      }

      p {
        color: white;

        font-size: 26px;
        letter-spacing: 2px;
        line-height: normal;
      }

      img {
        width: 450px;
        height: 100px;
      }
    }

    &.no-slides {
      cursor: default;
    }
  }

  .control-arrow {
    bottom: 100px;
    top: unset;

    opacity: 1;

    &:hover {
      background: url(/static/media/next.906689e9.svg) no-repeat center center;
    }

    &:before {
      border: none;
    }
  }

  .control-prev {
    left: 100px;

    background: url(../../../style/source/icons/next.svg) no-repeat center center;

    transform: rotate(180deg);
    transition: 0s;
  }

  .control-next {
    left: 415px;
    right: unset;

    background: url(../../../style/source/icons/next.svg) no-repeat center center;
    transition: 0s;
  }

  .control-dots {
    bottom: 117px;
    left: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 350px;
    margin: 0;
    padding: 0 5px;

    z-index: 1;

    .dot {
      width: 8px;
      height: 8px;

      color: rgba($color: white, $alpha: 0.5);

      transition: all 0.3s;

      margin: 0 8px 0 0;
    }

    .dot.selected {
      width: 11px;
      height: 11px;

      color: rgba($color: white, $alpha: 1);
    }
  }
}

.carousel .slide {
  background: transparent;
}

.carousel-overlay {
  position: absolute;
  top: 0;

  height: 100vh;
  width: 100%;
}

.new-slide {
  position: absolute;
  bottom: 100px;
  right: 200px;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 125px;
  width: 400px;
  border-radius: 10px;
  border: 4px dashed white;

  transition: 0.3s;
  cursor: pointer;

  .title {
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.5px;

    color: white;

    transition: 0.3s;
  }

  &:hover {
    border-color: #46bffc;

    .add-new {
      .title {
        color: #46bffc;
      }
    }
  }
}

.thumbnail {
  position: relative;

  height: 300px;
  width: 100%;

  .thumbnail-preview {
    position: absolute;

    height: 100%;
    width: 100%;
    margin-bottom: 5px;

    z-index: 1;
  }

  img {
    position: absolute;

    width: 100%;
    height: 100%;
  }
}

.not-clickable {
  cursor: default !important;
}

.slide-actions {
  position: absolute;
  bottom: 200px;
  left: 100px;

  display: flex;

  .edit-slide,
  .delete-slide {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 125px;
    width: 300px;
    margin-right: 20px;
    border-radius: 10px;
    border: 4px dashed white;

    cursor: pointer;
    transition: 0.3s;

    .title {
      font-size: 25px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1.5px;

      color: white;

      transition: 0.3s;
    }

    &:hover {
      border-color: #46bffc;

      .title {
        color: #46bffc;
      }
    }
  }
}

@media screen and (max-width: 475px) {
  .carousel.carousel-slider {
    position: relative;

    .image-carousel {
      position: relative;

      height: calc(100vh - 75px);

      cursor: pointer;

      .image {
        height: 100%;
        width: 100%;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
      }

      .slide-inner {
        position: absolute;
        top: 50%;

        padding: 0 20px 0 20px;
        // max-height: 600px;
        width: 100%;

        text-align: left;

        overflow: hidden;
        transform: translateY(-50%);

        h1 {
          margin: 15px 0 0 0;

          color: white;

          font-weight: 900;
          text-transform: uppercase;
          font-size: 23px;
          letter-spacing: 1px;
          line-height: normal;
        }

        p {
          margin: 0;

          color: white;

          font-size: 15px;
          letter-spacing: 2px;
          line-height: normal;
        }

        img {
          width: 155px;
          height: 35px;
        }
      }

      &.no-slides {
        cursor: default;
      }
    }

    .control-arrow {
      bottom: 20px;
      top: unset;

      padding: 20px;

      opacity: 1;

      // z-index: auto;

      &:hover {
        background: url(/static/media/next.906689e9.svg) no-repeat center center;
      }

      &:before {
        border: none;
      }
    }

    .control-prev {
      left: 20px;

      background: url(../../../style/source/icons/next.svg) no-repeat center center;

      transform: rotate(180deg);
      z-index: 1;
    }

    .control-next {
      right: 20px !important;
      left: unset;

      background: url(../../../style/source/icons/next.svg) no-repeat center center;
      z-index: 1;
    }

    .control-dots {
      bottom: 35px;
      left: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      width: 200px;
      margin: 0 auto;
      padding: 0 5px;

      z-index: auto;

      .dot {
        width: 8px;
        height: 8px;

        color: rgba($color: white, $alpha: 0.5);

        transition: all 0.3s;
        margin: 0 8px 0 0;
      }

      .dot.selected {
        width: 11px;
        height: 11px;

        color: rgba($color: white, $alpha: 1);
      }
    }
  }

  .slide-actions {
    position: absolute;
    bottom: 175px;
    left: 20px;

    display: flex;
    flex-direction: column;

    .edit-slide,
    .delete-slide {
      height: 50px;
      width: 175px;
      margin-bottom: 10px;
      border-radius: 10px;
      border: 4px dashed white;

      cursor: pointer;
      transition: 0.3s;

      .title {
        font-size: 17px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.5px;

        color: white;

        transition: 0.3s;
      }
    }
  }

  .new-slide {
    position: absolute;
    bottom: 110px;
    left: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 175px;
    border-radius: 10px;
    border: 4px dashed white;

    transition: 0.3s;
    cursor: pointer;

    .title {
      font-size: 17px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1.5px;

      color: white;

      transition: 0.3s;
    }

    &:hover {
      border-color: #46bffc;

      .add-new {
        .title {
          color: #46bffc;
        }
      }
    }
  }
}

@media screen and (min-width: 476px) and (max-width: 1024px) {
  .carousel.carousel-slider {
    position: relative;

    .image-carousel {
      position: relative;

      height: calc(100vh - 0px);

      cursor: pointer;

      .image {
        height: 100%;
        width: 100%;

        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50%;
      }

      .slide-inner {
        position: absolute;
        top: 50%;

        padding: 0 50px 0 50px;
        // max-height: 600px;
        width: 100%;

        text-align: left;

        overflow: hidden;
        transform: translateY(-50%);

        h1 {
          margin: 15px 0 0 0;

          color: white;

          font-weight: 900;
          text-transform: uppercase;
          font-size: 35px;
          letter-spacing: 1px;
          line-height: normal;
        }

        p {
          margin: 0;

          color: white;

          font-size: 25px;
          letter-spacing: 2px;
          line-height: normal;
        }

        img {
          width: 245px;
          height: 55px;
        }
      }

      &.no-slides {
        cursor: default;
      }
    }

    .control-arrow {
      bottom: 20px;
      top: unset;

      padding: 20px;

      opacity: 1;

      // z-index: auto;

      &:hover {
        background: url(/static/media/next.906689e9.svg) no-repeat center center;
      }

      &:before {
        border: none;
      }
    }

    .control-prev {
      left: 20px;

      background: url(../../../style/source/icons/next.svg) no-repeat center center;

      transform: rotate(180deg);
      z-index: 1;
    }

    .control-next {
      right: 20px !important;
      left: unset;

      background: url(../../../style/source/icons/next.svg) no-repeat center center;
      z-index: 1;
    }

    .control-dots {
      bottom: 50px;
      left: 0;
      right: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      width: 200px;
      margin: 0 auto;
      padding: 0 5px;

      z-index: auto;

      .dot {
        width: 8px;
        height: 8px;

        color: rgba($color: white, $alpha: 0.5);

        transition: all 0.3s;
        margin: 0 8px 0 0;
      }

      .dot.selected {
        width: 11px;
        height: 11px;

        color: rgba($color: white, $alpha: 1);
      }
    }
  }

  .slide-actions {
    position: absolute;
    bottom: 175px;
    left: 20px;

    display: flex;
    flex-direction: column;

    .edit-slide,
    .delete-slide {
      height: 50px;
      width: 175px;
      margin-bottom: 10px;
      border-radius: 10px;
      border: 4px dashed white;

      cursor: pointer;
      transition: 0.3s;

      .title {
        font-size: 17px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.5px;

        color: white;

        transition: 0.3s;
      }
    }
  }

  .new-slide {
    position: absolute;
    bottom: 110px;
    left: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    width: 175px;
    border-radius: 10px;
    border: 4px dashed white;

    transition: 0.3s;
    cursor: pointer;

    .title {
      font-size: 17px;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: 1.5px;

      color: white;

      transition: 0.3s;
    }

    &:hover {
      border-color: #46bffc;

      .add-new {
        .title {
          color: #46bffc;
        }
      }
    }
  }
}