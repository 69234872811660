*,
*::after,
*::before {
  box-sizing: border-box;

  outline: none;
}

body {
  padding: 0;
  margin: 0;

  font-family: 'Maven Pro', -apple-system, 'Segoe UI', Roboto, Helvetica, Arial,
    sans-serif;
}

.load-more-block,
.show-more-block {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px 0 0 0;

  span {
    padding: 10px 30px;
    border-radius: 10px;

    background: transparent;

    font-size: 18px;
    font-weight: bold;

    transition: 0.3s;
    cursor: pointer;

    &:hover {
      border: 2px solid #46bffc;

      color: #46bffc;
    }
  }
}

.text-centered {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.text-centered h1 {
  text-transform: uppercase;
  font-weight: bold;

  color: #46bffc;

  font-size: 50px;

  user-select: none;
}

.text-centered.animated h1 {
  animation: increaseFont 1s infinite;
}

.follow:hover,
.follow-reverse:hover {
  fill: #a8a1a1;
}

.follow_us,
.slide-caption,
.slide-text,
.companyLogo,
.follow,
.navigation li a,
.nav-lamp {
  transition: all 0.3s;
}

@keyframes increaseFont {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.95);
  }
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.loading-icon {
  width: 130px;
  height: 130px;
}

.follow.menu-mobile {
  fill: white !important;
}
