.videos-wrapper {
  // position: relative;

  min-height: calc(100vh - 120px);
  // height: calc(100vh - 175px);
  padding-top: 170px;
  padding-bottom: 85px;

  // overflow-y: scroll;

  span.goBack {
    display: flex;
    justify-content: flex-end;

    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: #5f5a5a;
    }
  }
}

.videos,
.video {
  height: 100%;
  max-width: 1250px;
  margin: 0 auto;

  ul {
    margin: 0;

    list-style: none;
  }

  &-filters {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 0 25px 0;

    li {
      display: flex;

      padding: 0 10px;

      color: #8e8e8e !important;

      font-weight: 500;
      font-size: 16px;
      text-align: center;

      transition: 0.3s;

      span {
        cursor: pointer;
      }

      &.active {
        text-decoration: underline;
      }

      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 45px;
        margin: 0 0 0 10px;

        .edit:hover,
        .delete:hover {
          color: #46bffc;

          transition: 0.3s;
        }
      }
    }
  }

  &-items {
    display: flex;

    ul {
      width: 50%;
      padding-left: 0;

      li {
        position: relative;

        .thumbnail {
          height: 100%;
          width: 100%;

          object-fit: cover;
        }

        .thumbnail.camera {
          position: absolute;
          top: calc(50% - 75px);
          left: calc(50% - 115px);
          right: 0;
          bottom: 0;

          height: 50%;
          width: 50%;
        }

        .description {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          background-color: rgba(0, 0, 0, 0);

          text-align: center;

          backdrop-filter: blur(5px);
          transition: 0.3s;
          opacity: 0;
          cursor: pointer;

          &-title {
            font-size: 19px;
            font-weight: 900;
          }

          &-author-name {
            font-size: 16px;
            letter-spacing: 1.25px;
          }

          &-title,
          &-author-name {
            color: white;
          }

          .actions {
            position: absolute;
            bottom: 5px;
            right: 5px;

            display: flex;

            .delete,
            .edit {
              margin-left: 5px;
              border-radius: 5px;

              color: white;

              font-size: 35px;

              transition: 0.3s;

              &:hover {
                color: #46bffc;
              }
            }
          }
        }

        p {
          margin: 0;
        }

        &:hover {
          // img {
          //  filter: blur(5px);
          // }

          .description {
            background-color: rgba(0, 0, 0, 0.45);

            opacity: 1;
          }
        }
      }

      &.left {
        li {
          padding: 0 8px 16px 0;

          transition: 0.5s;

          .description {
            padding: 0 35px 16px 35px;
            margin: 0px 8px 16px 0;
          }
        }
      }

      &.right {
        li {
          padding: 0 0 16px 8px;

          transition: 0.5s;

          .description {
            padding: 0 35px 16px 35px;
            margin: 0px 0 16px 8px;
          }
        }
      }
    }
  }

  .new {
    display: flex;

    .new-video,
    .new-category,
    .sort-button {
      position: relative;

      width: 100%;
      // margin: 0 0 16px 0;

      border-radius: 10px;

      transition: color 0.3s, border-color 0.3s;

      .add-new,
      .sort-videos {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        height: 100%;
        padding: 0;
        margin: 0;

        transition: color 0.3s, border-color 0.3s;
        cursor: pointer;

        .title {
          font-size: 25px;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1.5px;

          transition: 0.3s;
        }
      }

      &:hover {
        border-color: #46bffc;

        .add-new,
        .sort-videos {
          border-color: #46bffc;

          .title {
            color: #46bffc;
          }
        }
      }
    }

    .new-video {
      margin: 0 8px 16px 0;
    }

    .new-category {
      margin: 0 8px 16px 8px;
    }

    .sort-button {
      margin: 0 0 16px 8px;
    }
  }

  &.empty {
    display: flex;
    flex-direction: column;

    .empty-block {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100%;
    }

    .no-videos {
      margin: 0;

      font-size: 50px;
      font-weight: 800;
      letter-spacing: 3px;
    }
  }
}

// rewritten antd modal's styles

.ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.75);
}

.ant-modal-footer {
  .ant-btn {
    &:hover {
      border: 1px solid #46bffc;

      background-color: #46bffc;

      color: white;
    }

    &.ant-btn-primary {
      border: 1px solid #46bffc;

      background-color: #46bffc;

      color: white;

      &:hover {
        border: 1px solid #46bffc;

        background-color: white;

        color: #46bffc;
      }
    }
  }
}

.action-button-edit-modal {
  margin-top: 15px;
  margin-right: 10px;
  padding: 10px 20px;
  border: 1px solid #46bffc;
  border-radius: 5px;

  background: transparent;
  color: #46bffc;

  font-size: 16px;

  transition: 0.3s;
  cursor: pointer;

  &:hover {
    border: 1px solid #46bffc;

    background-color: #46bffc;
    color: white;
  }
}

.video iframe,
.video .vimeo {
  min-height: 600px;
  width: 100%;
  margin: 0;
}

.video-item-wrapper {
  padding: 15px 0;
}

.video-item {
  display: flex;

  &-number {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 50px;

    font-size: 20px;
    font-weight: bold;
  }

  &-name {
    flex: 1;

    font-size: 20px;
    font-weight: bold;
  }
}

.sort-videos-actions {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 150px;
  padding: 20px;

  transition: 0s;

  .btn {
    padding: 10px 30px;
    margin: 0 10px;
    border-radius: 10px;

    background: transparent;

    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    &-primary {
    }

    &-secondary {
    }
  }
}

@media screen and (max-width: 475px) {
  .videos-wrapper {
    position: relative;

    min-height: calc(100vh - 130px);
    padding-top: 80px;
    padding-bottom: 45px;
  }

  .videos,
  .video {
    height: 100%;
    max-width: 1250px;
    margin: 0 auto;

    ul {
      margin: 0;

      list-style: none;
    }

    &-filters {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 0 0 25px 0;

      li {
        display: flex;

        padding: 0 10px;

        color: #8e8e8e !important;

        font-size: 16px;
        font-weight: 500;
        text-align: center;
        letter-spacing: 0.85px;

        transition: 0.3s;

        span {
          cursor: pointer;
        }

        &.active {
          text-decoration: underline;
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 40px;
          margin: 0 0 0 10px;

          .edit:hover,
          .delete:hover {
            color: #46bffc;

            transition: 0.3s;
          }
        }
      }
    }

    &-items {
      display: flex;
      flex-direction: column;

      padding: 0 20px;

      ul {
        width: 100%;

        li {
          position: relative;

          .thumbnail {
            height: 100%;
            width: 100%;

            object-fit: cover;
          }

          .thumbnail.camera {
            position: absolute;
            top: calc(50% - 75px);
            left: calc(50% - 115px);
            right: 0;
            bottom: 0;

            height: 50%;
            width: 50%;
          }

          .description {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            background-color: rgba(0, 0, 0, 0.4);

            text-align: center;

            backdrop-filter: blur(3px);
            transition: 0.3s;
            opacity: 1;
            cursor: pointer;

            &-title {
              font-size: 19px;
              font-weight: 900;
            }

            &-author-name {
              font-size: 16px;
              letter-spacing: 1.25px;
            }

            &-title,
            &-author-name {
              color: white;
            }

            .actions {
              position: absolute;
              bottom: 10px;
              right: 10px;

              display: flex;

              .delete,
              .edit {
                margin-left: 5px;
                border-radius: 5px;

                color: white;

                font-size: 30px;

                transition: 0.3s;

                &:hover {
                  color: #46bffc;
                }
              }
            }
          }

          p {
            margin: 0;
          }
        }

        &.left {
          li {
            padding: 0 0 16px 0;

            .description {
              padding: 0 35px 16px 35px;
              margin: 0px 0 16px 0;
            }
          }
        }

        &.right {
          li {
            padding: 0 0 16px 0;

            .description {
              padding: 0 35px 16px 35px;
              margin: 0px 0 16px 0;
            }
          }
        }
      }
    }

    .new {
      display: flex;
      flex-direction: column;

      padding: 0 20px;

      .new-video,
      .new-category,
      .sort-button {
        position: relative;

        width: 100%;
        margin: 0 0 15px 0;

        border-radius: 10px;

        transition: 0.3s;

        .add-new {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          height: 100%;
          padding: 0;
          margin: 0;

          transition: 0.3s;
          cursor: pointer;

          .title {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1.5px;

            transition: 0.3s;
          }
        }

        &:hover {
          border-color: #46bffc;

          .add-new {
            .title {
              color: #46bffc;
            }
          }
        }
      }
    }

    &.empty {
      display: flex;
      flex-direction: column;

      .empty-block {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
      }

      .no-videos {
        margin: 0;

        font-size: 35px;
        font-weight: 800;
        letter-spacing: 3px;
      }
    }
  }
}

@media screen and (min-width: 476px) and (max-width: 768px) {
  .videos-wrapper {
    position: relative;

    min-height: calc(100vh - 130px);
    padding-top: 130px;
    padding-bottom: 45px;
  }

  .videos,
  .video {
    height: 100%;
    max-width: 1250px;
    margin: 0 auto;

    ul {
      margin: 0;

      list-style: none;
    }

    &-filters {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 0 0 25px 0;

      li {
        display: flex;

        padding: 0 10px;

        color: #8e8e8e !important;

        font-size: 22px;
        font-weight: 500;
        text-align: center;
        letter-spacing: 0.85px;

        transition: 0.3s;

        span {
          cursor: pointer;
        }

        &.active {
          text-decoration: underline;
        }

        .actions {
          display: flex;
          justify-content: space-between;
          align-items: center;

          width: 40px;
          margin: 0 0 0 10px;

          .edit:hover,
          .delete:hover {
            color: #46bffc;

            transition: 0.3s;
          }
        }
      }
    }

    &-items {
      display: flex;
      flex-direction: column;

      padding: 0 20px;

      ul {
        width: 100%;

        li {
          position: relative;

          margin: 0 auto;
          max-width: 450px;

          .thumbnail {
            height: 100%;
            width: 100%;

            object-fit: cover;
          }

          .thumbnail.camera {
            position: absolute;
            top: calc(50% - 75px);
            left: calc(50% - 115px);
            right: 0;
            bottom: 0;

            height: 50%;
            width: 50%;
          }

          .description {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            background-color: rgba(0, 0, 0, 0.4);

            text-align: center;

            backdrop-filter: blur(3px);
            transition: 0.3s;
            opacity: 1;
            cursor: pointer;

            &-title {
              font-size: 19px;
              font-weight: 900;
            }

            &-author-name {
              font-size: 16px;
              letter-spacing: 1.25px;
            }

            &-title,
            &-author-name {
              color: white;
            }

            .actions {
              position: absolute;
              bottom: 10px;
              right: 10px;

              display: flex;

              .delete,
              .edit {
                margin-left: 5px;
                border-radius: 5px;

                color: white;

                font-size: 30px;

                transition: 0.3s;

                &:hover {
                  color: #46bffc;
                }
              }
            }
          }

          p {
            margin: 0;
          }
        }

        &.left {
          li {
            padding: 0 0 16px 0;

            .description {
              padding: 0 35px 16px 35px;
              margin: 0px 0 16px 0;
            }
          }
        }

        &.right {
          li {
            padding: 0 0 16px 0;

            .description {
              padding: 0 35px 16px 35px;
              margin: 0px 0 16px 0;
            }
          }
        }
      }
    }

    .new {
      display: flex;
      flex-direction: column;

      padding: 0 20px;

      .new-video,
      .new-category,
      .sort-button {
        position: relative;

        width: 100%;
        margin: 0 0 15px 0;

        border-radius: 10px;

        transition: 0.3s;

        .add-new {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          display: flex;
          justify-content: center;
          align-items: center;

          height: 100%;
          padding: 0;
          margin: 0;

          transition: 0.3s;
          cursor: pointer;

          .title {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1.5px;

            transition: 0.3s;
          }
        }

        &:hover {
          border-color: #46bffc;

          .add-new {
            .title {
              color: #46bffc;
            }
          }
        }
      }
    }

    &.empty {
      display: flex;
      flex-direction: column;

      .empty-block {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;
      }

      .no-videos {
        margin: 0;

        font-size: 35px;
        font-weight: 800;
        letter-spacing: 3px;
      }
    }
  }
}
