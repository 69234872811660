.header-wrapper {
  position: relative;

  height: 100%;
  min-height: 100vh;
  padding: 78px 0 0 0;

  .images {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    padding: 78px 0 0 0;

    img {
      position: absolute;

      // width: 600px;
      // height: 350px;

      opacity: 0.3;
      object-fit: cover;

      &:first-child {
        top: 78px;
        left: 0;
      }

      &:last-child {
        bottom: 0;
        right: 0;
      }
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: 100%;

    &-logo {
      height: 100px;
      width: 450px;
      margin-bottom: 35px;
    }

    .description {
      max-width: 550px;

      font-size: 22px;
      font-weight: 900;
      text-align: center;
      letter-spacing: 1.15px;

      &-second {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: 0.75px;
      }
    }

    span.edit-texts {
      font-weight: bold;
      font-size: 25px;
      text-transform: uppercase;

      cursor: pointer;
      transition: 0.3s;

      &:hover {
        color: #46bffc;
      }
    }
  }
}

.work-wrapper {
  padding: 100px 0 40px 0;

  &-title {
    font-size: 37px;
    text-align: center;

    transition: 0.3s;
  }

  .partners {
    &-list {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 16px;

      padding: 0;
      margin: 0;

      list-style: none;

      .new-partner {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 130px;
        border-radius: 10px;

        transition: 0.3s;

        cursor: pointer;

        span {
          font-size: 15px;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1.5px;

          transition: 0.3s;
        }

        &:hover {
          border-color: #46bffc;

          span {
            color: #46bffc;
          }
        }
      }

      .partner {
        position: relative;

        height: 130px;
        border-radius: 10px;

        transition: 0.3s;

        img {
          height: 100%;
          width: 100%;

          // object-fit: cover;
        }

        .actions {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          transition: 0.3s;
          opacity: 0;

          .delete {
            position: absolute;
            right: 5px;
            bottom: 5px;

            color: #46bffc;

            font-size: 35px;
          }
        }

        &:hover {
          .actions {
            background-color: rgba($color: black, $alpha: 0.4);
            opacity: 1;
          }
        }
      }
    }
  }
}

.team-wrapper {
  position: relative;

  min-height: calc(100vh - 120px);
  // height: calc(100vh - 175px);
  max-width: 1160px;
  padding: 80px 60px 35px 60px;
  margin: 0 auto;

  // overflow-y: scroll;
  transition: 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }
}

.team {
  display: flex;

  &-description {
    width: 25%;
    margin-right: 40px;

    p {
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.75px;

      &.blue-text {
        margin: 35px 0;

        color: #46bffc;

        font-size: 21px;
        font-weight: 500;
        line-height: 1.25;
      }
    }

    p:first-child {
      margin-top: 0;
    }
  }

  &-members {
    &-title {
      font-size: 37px;
      text-align: center;

      transition: 0.3s;
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 40px;

      padding: 0;
      margin: 0;

      list-style: none;

      .team-member {
        position: relative;

        width: 100%;

        p {
          margin: 0;
        }

        img {
          height: 250px;
          width: 100%;

          object-fit: cover;
        }

        .name {
          position: relative;

          padding-right: 20px;
          margin-top: 22px;

          text-transform: uppercase;
          font-size: 14px;
          font-weight: 900;

          a {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        a.name {
          display: block;

          color: #46bffc;

          text-decoration: none;
          transition: 0.3s;

          &:hover {
            color: darken($color: #46bffc, $amount: 20%);

            text-decoration: underline;
          }
        }

        .speciality {
          font-size: 14px;
          line-height: 1.5;
          letter-spacing: 0.75px;
        }

        .watch-images {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;

          padding: 20px;
          height: 250px;

          background-color: rgba(0, 0, 0, 1);

          // text-align: center;

          backdrop-filter: blur(3px);
          transition: 0.3s;
          opacity: 0;
          // overflow-y: scroll;
          line-height: 1.3;
          // cursor: pointer;

          span {
            color: white;

            font-size: 11px;
            font-weight: 300;
            line-height: 1.50;

            transition: 0.3s;
          }

          .actions {
            position: absolute;
            bottom: 5px;
            right: 5px;

            display: flex;

            transition: 0.3s;
            opacity: 0;

            .delete,
            .edit {
              margin-left: 5px;
              border-radius: 5px;

              color: #46bffc;

              font-size: 35px;

              transition: 0.3s;

              &:hover {
                color: darken($color: #46bffc, $amount: 20%);
              }
            }
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.7);

            opacity: 1;

            .actions {
              opacity: 1;
            }
          }
        }
      }

      .new-team-member {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 300px;
        border-radius: 10px;

        transition: 0.3s;

        cursor: pointer;

        span {
          font-size: 18px;
          text-transform: uppercase;
          font-weight: bold;
          letter-spacing: 1.5px;

          transition: 0.3s;
        }

        &:hover {
          border-color: #46bffc;

          span {
            color: #46bffc;
          }
        }
      }

      .no-team-members {
        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
    }
  }
}

.team-members-carousel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  height: 100vh;
  width: 100vw;

  background-color: rgba($color: black, $alpha: 1);

  z-index: 1;

  .control-arrow.control-next {
    right: 100px;
    left: unset;
  }

  .stop {
    position: absolute;
    top: 50px;
    right: 50px;

    width: 35px;

    color: white;

    font-size: 35px;
  }

  &.animated {
    animation: fadeIn 0.5s;
  }

  .carousel {
    height: 100vh;

    .slide {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 100vh;

      img {
        width: auto;
        max-width: 1200px;
      }
    }
  }

  .carousel-member {
    margin: 0 auto;

    .inner {
      position: relative;

      .caption {
        position: absolute;
        top: 0;

        width: 100%;
        padding: 20px 40px;

        // background-color: rgba($color: black, $alpha: 0.4);

        h2,
        h4 {
          margin: 0;

          color: white;

          text-align: left;
        }
      }

      img {
        max-height: 800px;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.action-button-edit-modal {
  margin-top: 15px;
  margin-right: 10px;
  padding: 10px 20px;
  border: 1px solid #46bffc;
  border-radius: 5px;

  background: transparent;
  color: #46bffc;

  font-size: 16px;

  transition: 0.3s;
  cursor: pointer;

  &:hover {
    border: 1px solid #46bffc;

    background-color: #46bffc;
    color: white;
  }
}

.default-style {
  background: transparent !important;

  // cursor: default !important;
  opacity: 0 !important;
}

@media screen and (max-width: 475px) {
  .team-wrapper {
    min-height: calc(100vh - 120px);
    padding: 100px 20px 45px 20px;
  }

  .team {
    display: flex;
    flex-direction: column;

    &-description {
      width: 100%;
      margin: 0;

      p {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.75px;

        &.blue-text {
          margin: 35px 0;

          color: #46bffc;

          font-size: 21px;
          font-weight: 500;
          line-height: 1.25;
        }
      }

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 30px;
      }
    }

    &-members {
      width: 100%;

      &-list {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 40px;

        padding: 0;
        margin: 0;

        list-style: none;

        .team-member {
          position: relative;

          width: 100%;

          p {
            margin: 0;
          }

          img {
            height: 350px;
            width: 100%;

            object-fit: cover;
          }

          .name {
            position: relative;

            padding-right: 20px;
            margin-top: 10px;

            text-transform: uppercase;
            font-size: 19px;
            font-weight: 900;

            a {
              position: absolute;
              top: 0;
              right: 0;
            }
          }

          a.name {
            display: block;

            color: #46bffc;

            text-decoration: none;
            transition: 0.3s;

            &:hover {
              color: darken($color: #46bffc, $amount: 20%);

              text-decoration: underline;
            }
          }

          .speciality {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.75px;
          }

          .watch-images {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;

            padding: 20px;
            height: 350px;

            background-color: rgba(0, 0, 0, 1);

            // text-align: center;

            backdrop-filter: blur(3px);
            transition: 0.3s;
            opacity: 0;
            // cursor: pointer;

            span {
              color: white;

              font-size: 13px;
              // text-transform: uppercase;
              font-weight: 400;
              line-height: 1.50;

              transition: 0.3s;
            }

            .actions {
              position: absolute;
              bottom: 5px;
              right: 5px;

              display: flex;

              transition: 0.3s;
              opacity: 0;

              .delete,
              .edit {
                margin-left: 5px;
                border-radius: 5px;

                color: #46bffc;

                font-size: 35px;

                transition: 0.3s;

                &:hover {
                  color: darken($color: #46bffc, $amount: 20%);
                }
              }
            }

            &:hover {
              background-color: rgba(0, 0, 0, 0.7);

              opacity: 1;

              .actions {
                opacity: 1;
              }
            }
          }
        }

        .new-team-member {
          display: flex;
          justify-content: center;
          align-items: center;

          min-height: 300px;
          border-radius: 10px;

          transition: 0.3s;

          cursor: pointer;

          span {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1.5px;

            transition: 0.3s;
          }

          &:hover {
            border-color: #46bffc;

            span {
              color: #46bffc;
            }
          }
        }

        .no-team-members {
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
  }

  .team-members-carousel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    background-color: rgba($color: black, $alpha: 1);

    z-index: 1;

    .stop {
      position: absolute;
      top: 25px;
      right: 25px;

      color: white;

      font-size: 50px;
    }

    &.animated {
      animation: fadeIn 0.5s;
    }

    .carousel {
      height: calc(100vh - 75px);

      .slide {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100vh;

        img {
          width: auto;
          max-width: 1200px;
        }
      }
    }

    .carousel-member {
      margin: 0 auto;

      .inner {
        position: relative;

        .caption {
          position: absolute;
          bottom: 0;

          width: 100%;
          padding: 10px;

          background-color: rgba($color: black, $alpha: 0.4);

          h2,
          h4 {
            color: white;
          }
        }

        img {
          max-height: 800px;
        }
      }
    }
  }
}

@media screen and (min-width: 476px) and (max-width: 950px) {
  .team-wrapper {
    min-height: calc(100vh - 120px);
    padding: 130px 20px 45px 20px;
  }

  .team {
    display: flex;
    flex-direction: column;

    &-description {
      width: 100%;
      margin: 0;

      p {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.75px;

        &.blue-text {
          margin: 35px 0;

          color: #46bffc;

          font-size: 21px;
          font-weight: 500;
          line-height: 1.25;
        }
      }

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 30px;
      }
    }

    &-members {
      width: 100%;

      &-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;

        padding: 0;
        margin: 0;

        list-style: none;

        .team-member {
          position: relative;

          p {
            margin: 0;
          }

          img {
            height: 250px;
            width: 100%;

            object-fit: cover;
          }

          .name {
            position: relative;

            padding-right: 20px;
            margin-top: 10px;

            text-transform: uppercase;
            font-size: 19px;
            font-weight: 900;

            a {
              position: absolute;
              top: 0;
              right: 0;
            }
          }

          a.name {
            display: block;

            color: #46bffc;

            text-decoration: none;
            transition: 0.3s;

            &:hover {
              color: darken($color: #46bffc, $amount: 20%);

              text-decoration: underline;
            }
          }

          .speciality {
            font-size: 14px;
            line-height: 1.5;
            letter-spacing: 0.75px;
          }

          .watch-images {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            // display: flex;
            // flex-direction: column;
            // justify-content: center;
            // align-items: center;

            padding: 20px;
            height: 250px;

            background-color: rgba(0, 0, 0, 1);

            // text-align: center;

            backdrop-filter: blur(3px);
            transition: 0.3s;
            opacity: 0;
            // cursor: pointer;

            span {
              color: white;

              font-size: 13px;
              font-weight: 400;
              line-height: 1.50;

              transition: 0.3s;
            }

            .actions {
              position: absolute;
              bottom: 5px;
              right: 5px;

              display: flex;

              transition: 0.3s;
              opacity: 0;

              .delete,
              .edit {
                margin-left: 5px;
                border-radius: 5px;

                color: #46bffc;

                font-size: 35px;

                transition: 0.3s;

                &:hover {
                  color: darken($color: #46bffc, $amount: 20%);
                }
              }
            }

            &:hover {
              background-color: rgba(0, 0, 0, 0.7);

              opacity: 1;

              .actions {
                opacity: 1;
              }
            }
          }
        }

        .new-team-member {
          display: flex;
          justify-content: center;
          align-items: center;

          min-height: 300px;
          border-radius: 10px;

          transition: 0.3s;

          cursor: pointer;

          span {
            font-size: 18px;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: 1.5px;

            transition: 0.3s;
          }

          &:hover {
            border-color: #46bffc;

            span {
              color: #46bffc;
            }
          }
        }

        .no-team-members {
          display: flex;
          justify-content: center;
          align-items: center;

          font-size: 20px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
  }

  .team-members-carousel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    background-color: rgba($color: black, $alpha: 1);

    z-index: 1;

    .stop {
      position: absolute;
      top: 25px;
      right: 25px;

      color: white;

      font-size: 50px;
    }

    &.animated {
      animation: fadeIn 0.5s;
    }

    .carousel {
      height: calc(100vh - 0px);

      .slide {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100vh;

        img {
          width: auto;
          max-width: 1200px;
        }
      }
    }

    .carousel-member {
      margin: 0 auto;

      .inner {
        position: relative;

        .caption {
          position: absolute;
          bottom: 0;

          width: 100%;
          padding: 10px;

          background-color: rgba($color: black, $alpha: 0.4);

          h2,
          h4 {
            color: white;
          }
        }

        img {
          max-height: 800px;
        }
      }
    }
  }
}

@media screen and (max-width: 474px) {
  .header-wrapper {
    padding: 70px 20px 0 20px;

    .images {
      padding: 70px 0 0 0;

      img {
        width: 100%;
        height: calc(50%);

        &:first-child {
          top: 0;
        }
      }
    }

    .content {
      svg {
        width: 250px;
      }

      .description {
        margin-top: 20px;

        font-size: 15px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .work-wrapper {
    .partners-list {
      grid-template-columns: repeat(2, 1fr);

      .partner {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 170px;

        img {
          object-fit: contain;
        }
      }
    }
  }
}

@media screen and (min-width: 475px) and (max-width: 1023px) {
  .header-wrapper {
    padding: 78px 20px 0 20px;

    .images {
      padding: 78px 0 0 0;

      img {
        width: 100%;
        height: calc(50%);

        &:first-child {
          top: 0;
        }
      }
    }

    .content {
      .description {
        margin-top: 20px;

        font-size: 15px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .work-wrapper {
    .partners-list {
      grid-template-columns: repeat(3, 1fr);

      .partner {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 170px;

        img {
          object-fit: contain;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .header-wrapper {
    .images {
      img {
        // width: 500px;
        // height: 300px;
      }
    }
  }

  .work-wrapper {
    .partners-list {
      grid-template-columns: repeat(4, 1fr);

      .partner {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 150px;

        img {
          object-fit: contain;
        }
      }
    }
  }
}