.follow_us {
  position: fixed;
  right: -135px;
  top: calc(50% - 100px);

  width: 160px;
  height: 190px;
  padding: 10px 15px;
  border-top-right-radius: 10px;
  border-top-left-radius: 50%;

  transform: rotate(-90deg);
  box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.6);
  cursor: pointer;

  p {
    position: absolute;
    right: 21px;
    top: 7px;

    margin: 0;

    font-size: 19px;

    user-select: none;
  }

  ul.links {
    position: absolute;
    right: 55px;
    bottom: 85px;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

    padding: 0;
    margin: 0;
    height: 100px;

    transform: rotate(90deg);
    list-style: none;

    li {
      width: 15px;
      height: 15px;

      text-align: center;

      cursor: pointer;
    }
  }

  &:hover {
    right: -100px;
  }
}

@media screen and (max-width: 768px) {
  .follow_us {
    display: none;
  }
}
