.videos-carousel-wrapper,
.video-wrapper {
  position: relative;

  max-width: 1160px;
  min-height: calc(100vh - 120px);
  // height: calc(100vh - 175px);
  padding: 170px 60px 45px 60px;
  margin: 0 auto;

  // overflow-y: scroll;

  transition: 0.3s;

  &::-webkit-scrollbar {
    display: none;
  }

  span.goBack {
    display: flex;
    justify-content: flex-end;

    color: #46bffc;

    transition: 0.3s;
    cursor: pointer;

    &:hover {
      color: #5f5a5a;
    }
  }
}

.videos-carousel,
.video {
  .video {
    .vimeo {
      min-height: 600px;
    }

    &-description {
      display: flex;
      justify-content: space-between;

      padding: 20px 60px 0 60px;

      .main-info {
        width: 60%;
      }

      h1,
      p {
        text-align: left;
      }

      h1 {
        margin: 0 0 0 0;

        font-size: 46px;
        font-weight: 900;
      }

      p {
        margin: 0;

        font-size: 26px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 2px;
      }
    }

    &-additional-description {
      display: flex;
      flex-direction: column;

      max-width: 300px;

      span {
        text-align: right;
        font-size: 13px;
        line-height: 1.5;
      }

      .main {
        font-size: 13px;
        font-weight: 400;
        line-height: 1.5;
        font-weight: 800;

        span {
          font-weight: 400;
        }
      }
    }
  }

  .carousel.carousel-slider {
    overflow: visible;
  }

  .carousel.carousel-slider .control-next,
  .carousel.carousel-slider .control-prev {
    left: unset;
    bottom: unset;
    top: 635px;
  }

  .carousel.carousel-slider .control-prev {
    background: url(../../../style/source/icons/arrow-left.svg) no-repeat center
      center;

    transform: rotate(0);

    // &:hover {
    //   background: url(../../../style/source/icons/arrow-left.svg) no-repeat center center;

    //   transform: rotate(0);
    // }
  }

  .carousel.carousel-slider .control-next {
    background: url(../../../style/source/icons/arrow-right.svg) no-repeat
      center center;

    right: 0;
    // &:hover {
    //   background: url(../../../style/source/icons/arrow-right.svg) no-repeat center center;
    // }
  }
}

.carousel .slide iframe {
  width: 100%;
  margin: 0;
}

.video-wrapper.empty {
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-bottom: 5px;

    font-weight: 900;
    text-transform: uppercase;
    font-size: 50px;
    letter-spacing: 1px;
    line-height: normal;
  }
}

@media screen and (max-width: 475px) {
  .videos-carousel-wrapper,
  .video-wrapper {
    position: relative;

    max-width: 1160px;
    min-height: calc(100vh - 120px);
    padding: 80px 0 45px 0;
    margin: 0 auto;

    // overflow-y: scroll;

    transition: 0.3s;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .videos-carousel,
  .video {
    .video {
      .vimeo {
        min-height: unset;
        max-height: 262px;
        width: 100%;

        iframe {
          min-height: unset;
          max-height: 262px;
          width: 100%;
        }
      }

      &-description {
        display: flex;
        flex-direction: column;

        padding: 20px 20px 0 20px;

        .main-info {
          width: 100%;
          margin-bottom: 30px;
        }

        h1,
        p {
          text-align: left;
        }

        h1 {
          margin: 0;

          font-size: 23px;
          font-weight: 900;
        }

        p {
          margin: 0;

          font-size: 20px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 2px;
        }
      }

      &-additional-description {
        display: flex;
        flex-direction: column;

        span {
          text-align: left;
          font-size: 13px;
          line-height: 1.5;
        }

        .main {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          font-weight: 800;

          span {
            font-weight: 400;
          }
        }
      }
    }

    .carousel.carousel-slider {
      overflow: visible;
    }

    .carousel.carousel-slider .control-next,
    .carousel.carousel-slider .control-prev {
      left: unset;
      bottom: unset;
      top: 465px;

      z-index: auto;
    }

    .carousel.carousel-slider .control-prev {
      background: url(../../../style/source/icons/arrow-left.svg) no-repeat
        center center;

      transform: rotate(0);
      left: 20px !important;

      // &:hover {
      //   background: url(../../../style/source/icons/arrow-left.svg) no-repeat center center;

      //   transform: rotate(0);
      // }
    }

    .carousel.carousel-slider .control-next {
      background: url(../../../style/source/icons/arrow-right.svg) no-repeat
        center center;

      right: 20px !important;
      // &:hover {
      //   background: url(../../../style/source/icons/arrow-right.svg) no-repeat center center;
      // }
    }
  }
}

@media screen and (min-width: 476px) and (max-width: 768px) {
  .videos-carousel-wrapper,
  .video-wrapper {
    position: relative;

    max-width: 1160px;
    min-height: calc(100vh - 120px);
    padding: 130px 0 45px 0;
    margin: 0 auto;

    // overflow-y: scroll;

    transition: 0.3s;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .videos-carousel,
  .video {
    .video {
      .vimeo {
        min-height: unset;
        max-height: 450px;
        width: 100%;

        iframe {
          min-height: unset;
          max-height: 450px;
          width: 100%;
        }
      }

      &-description {
        display: flex;
        flex-direction: column;

        padding: 0 60px 0 60px;

        .main-info {
          width: 100%;
          margin-bottom: 30px;
        }

        h1,
        p {
          text-align: left;
        }

        h1 {
          margin: 0;

          font-size: 23px;
          font-weight: 900;
        }

        p {
          margin: 0;

          font-size: 20px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 2px;
        }
      }

      &-additional-description {
        display: flex;
        flex-direction: column;

        span {
          text-align: left;
          font-size: 13px;
          line-height: 1.5;
        }

        .main {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          font-weight: 800;

          span {
            font-weight: 400;
          }
        }
      }
    }

    .carousel.carousel-slider {
      overflow: visible;
    }

    .carousel.carousel-slider .control-next,
    .carousel.carousel-slider .control-prev {
      left: unset;
      bottom: unset;
      top: 465px;

      z-index: 1;
    }

    .carousel.carousel-slider .control-prev {
      background: url(../../../style/source/icons/arrow-left.svg) no-repeat
        center center;

      transform: rotate(0);
      left: 20px !important;

      // &:hover {
      //   background: url(../../../style/source/icons/arrow-left.svg) no-repeat center center;

      //   transform: rotate(0);
      // }
    }

    .carousel.carousel-slider .control-next {
      background: url(../../../style/source/icons/arrow-right.svg) no-repeat
        center center;

      right: 20px !important;
      // &:hover {
      //   background: url(../../../style/source/icons/arrow-right.svg) no-repeat center center;
      // }
    }
  }
}
