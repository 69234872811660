.login-wrapper {
  padding: 0 40px;

  background: white !important;
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;

  .login-logo {
    width: 300px;
  }

  .ant-form-item-required {
    color: black;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 300px;

    .action-buttons {
      display: flex;
      justify-content: space-between;

      width: 100%;

      .login-button,
      .back-button {
        width: 140px;
        height: 50px;

        font-size: 16px;
        font-weight: 500;
      }

      .login-button,
      .login-button:focus {
        border-color: black;
        background-color: white;
        color: black;
      }

      .login-button:hover,
      .login-button:active {
        background-color: black;
        border-color: white;
        color: white;
      }

      .back-button {
        border-color: white;
        background-color: black;
        color: white;

        transition: 0.3s;

        a {
          transition: 0s;
        }
      }

      .back-button:hover,
      .back-button:focus,
      .back-button:active {
        background-color: white;
        border-color: black;
        color: black;
      }
    }

    .ant-row.ant-form-item {
      width: 100%;
    }

    .ant-input {
      height: 50px;

      font-size: 18px;
    }
  }
}

.login-spin {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 50px;
  width: 100%;
}
