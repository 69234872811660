.navigation {
  position: fixed;
  top: 0;

  width: 100%;
  padding: 5px 0;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

  z-index: 999;

  ul {
    li.logo-mobile {
      display: none;
    }
  }

  ul.navigation-menus-mobile {
    display: none;
  }

  .menu__btn {
    display: none;
  }

  .mobile-menu-wrapper {
    display: none;
  }

  ul {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0;
    margin: 0;

    list-style: none;

    li {
      display: flex;
      justify-content: center;

      min-width: 120px;

      font-weight: 500;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 0.85px;
      text-decoration: none;
      text-align: center;

      a {
        max-height: 68px;
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.85px;
        text-decoration: none;

        &.no-styles {
          font-weight: initial;
          font-size: initial;
          text-transform: initial;
          letter-spacing: initial;
          text-decoration: initial;
        }

        &:hover {
          color: #a8a1a1;
        }
      }

      .ant-dropdown-menu {
        display: flex;
        flex-direction: column;

        box-shadow: none;

        background: transparent;

        &-item {
          padding: 0;
          margin: 0 0 4px 0;

          background-color: transparent;

          font-size: 14px;

          a {
            color: #46bffc;

            font-size: 13px;
            letter-spacing: 0;
            font-weight: normal;
          }

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    li:last-child {
      color: rgb(179, 38, 38);

      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      letter-spacing: 0.85px;
      text-decoration: none;
      text-align: center;

      cursor: pointer;
    }

    li.logo {
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0;
      height: 100%;
      width: 140px;

      a {
        padding: 12px 15px;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-top-left-radius: 50%;
        border-top-right-radius: 5px;

        svg {
          height: 60px;
          width: 60px;
        }
      }
    }

    li a.active {
      color: #46bffc;
    }
  }

  .lamp {
    position: fixed;
    top: 25px;
    right: 50px;

    cursor: pointer;
  }

  .menu-mobile {
    position: fixed;
    bottom: 30px;

    display: flex;
    justify-content: space-evenly;
    align-items: center;

    width: 100%;

    li {
      min-width: unset;
      padding: 0;

      a {
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
}

.navigation.no-shadow {
  box-shadow: none;
}

.navigation .default {
  color: white;
}


@media screen and (max-width: 475px) {
  .navigation {
    display: flex;

    height: 70px;
    padding: 15px 20px;

    ul.navigation-menus {
      display: none;
    }

    .mobile-menu-wrapper.menu-opened {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;

      z-index: 99;
    }

    ul.navigation-menus-mobile.menu-opened {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      padding: 20px 0 20px 0;
      height: calc(100vh - 100px);
      width: 100%;

      overflow: hidden;

      li {
        min-width: unset;
        padding: 0;
      }
    }

    .lamp {
      top: 20px;
      right: 75px;
    }

    ul {
      list-style: none;

      li.logo-mobile {
        display: block;
        justify-content: center;
        align-items: center;

        min-width: unset;
        width: 40px;
        height: 40px;
        padding: 0;

        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-top-left-radius: 50%;
        border-top-right-radius: 5px;

        a {
          svg {
            height: 100%;
            width: 25px;
          }
        }
      }
    }

    .menu__btn {
      display: flex;
      align-items: center;
      position: fixed;
      top: 22px;
      right: 20px;

      width: 26px;
      height: 26px;

      cursor: pointer;
      z-index: 99999;
    }

    .menu__btn>span,
    .menu__btn>span::before,
    .menu__btn>span::after {
      display: block;
      position: absolute;

      width: 100%;
      height: 2px;

      // background-color: white;

      transition-duration: 0.25s;
    }

    .menu__btn.white-menu>span,
    .menu__btn.white-menu>span::before,
    .menu__btn.white-menu>span::after {
      background-color: white;
    }

    .menu__btn>span::before {
      content: '';
      top: -8px;
    }

    .menu__btn>span::after {
      content: '';
      top: 8px;
    }
  }
}

@media screen and (min-width: 476px) and (max-width: 768px) {
  .navigation {
    display: flex;
    align-items: center;

    height: 100px;
    padding: 15px 20px;

    ul.navigation-menus {
      display: none;
    }

    .mobile-menu-wrapper.menu-opened {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;

      z-index: 99;
    }

    ul.navigation-menus-mobile.menu-opened {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      padding: 40px 0 0 0;
      height: calc(100vh - 100px);
      width: 100%;

      overflow: hidden;

      li {
        min-width: unset;
        padding: 0;
      }
    }

    .lamp {
      top: 30px;
      right: 75px;
    }

    ul {
      list-style: none;

      li.logo-mobile {
        display: block;
        justify-content: center;
        align-items: center;

        min-width: unset;
        width: 65px;
        height: 65px;
        padding: 0;

        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-top-left-radius: 50%;
        border-top-right-radius: 5px;

        a {
          svg {
            height: 100%;
            width: 40px;
          }
        }
      }
    }

    .menu__btn {
      display: flex;
      align-items: center;
      position: fixed;
      top: 30px;
      right: 20px;

      width: 26px;
      height: 26px;

      cursor: pointer;
      z-index: 99999;
    }

    .menu__btn>span,
    .menu__btn>span::before,
    .menu__btn>span::after {
      display: block;
      position: absolute;

      width: 100%;
      height: 2px;

      // background-color: white;

      transition-duration: 0.25s;
    }

    .menu__btn.white-menu>span,
    .menu__btn.white-menu>span::before,
    .menu__btn.white-menu>span::after {
      background-color: white;
    }

    .menu__btn>span::before {
      content: '';
      top: -8px;
    }

    .menu__btn>span::after {
      content: '';
      top: 8px;
    }
  }
}

@media screen and (max-width: 768px) {
  .navigation {
    .mobile-menu-wrapper {
      ul {
        li {
          .ant-dropdown-menu {
            display: flex;
            flex-direction: row;

            box-shadow: none;

            background: transparent;

            &-item {
              padding: 0;
              margin: 0;

              background-color: transparent;

              font-size: 14px;

              a {
                padding: 0;
                margin: 15px;

                color: #46bffc;

                font-size: 13px;
                letter-spacing: 0;
                font-weight: normal;
              }

              &:hover {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}