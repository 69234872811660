.not-found {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;

  h1 {
    text-transform: uppercase;
    font-weight: bold;

    color: #46bffc;

    font-size: 50px;
    user-select: none;
  }
}

@media screen and (max-width: 475px) {
  .not-found {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;

    h1 {
      text-transform: uppercase;
      font-weight: bold;

      color: #46bffc;

      font-size: 35px;
      user-select: none;
    }
  }
}
